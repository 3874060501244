.same-column-row {
  padding: 6px;
}

.same-column-row-no-padding {
  padding: 0px;
}

.same-column-row-no-side-padding {
  padding: 6px 0px;
}

.one-column-row {
  padding: 12px 0;
  width: 100%;
}

.one-column-row-no-padding {
  padding: 0px;
  width: 100%;
}

.two-column-row {
  padding: 12px;
}

.two-column-row-no-padding {
  padding: 0px;
}

.three-column-row {
  padding: 6px 0;
  width: 100%;
}

.three-column-row-no-padding {
  padding: 0;
  width: 100%;
}

@media (max-width: 600px) {
  .one-column-row {
    padding: 8px 0;
  }
  .two-column-row {
    padding: 8px;
  }
}

@media (max-width: 400px) {
  .one-column-row {
    padding: 6px 0;
  }
  .two-column-row {
    padding: 6px;
  }
  .three-column-row {
    padding: 4px 0;
  }
}
