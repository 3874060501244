.ant-layout-sider-zero-width-trigger {
  top: 6px;
  height: 64px;
  width: 64px;
  line-height: 64px;
  font-size: 32px;
  right: -64px;
  /* color: #f0f2f5; */
  background: #00000000;
  box-shadow: 2px 2px 4px #1f1f1f1f;
}

.ant-layout-sider-zero-width-trigger:hover {
  /* color: #f0f2f5; */
  background: #00000000;
}

.sider {
  position: fixed;
  min-height: -webkit-fill-available;
  height: 100%;
  z-index: 3;
}

.menu {
  padding-top: 24px;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.navigation-img {
  height: 100px !important;
  padding-bottom: 16px;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 990px) {
  .ant-layout-sider {
    position: absolute;
  }
}

@media (max-width: 400px) {
  .ant-layout-sider-zero-width-trigger {
    top: 2px;
  }
}
