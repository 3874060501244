.category-select {
  width: 80%;
  max-width: 150px;
}

.category-row {
  padding: 0px;
}

.category-option-div {
  width: 0px;
  padding: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
