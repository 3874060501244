.exercise-img {
  filter: invert(0.9);
  padding: 2px 4px 2px 0;
  /* width: 40em; */
  height: 32px;
}

.exercise-img-full {
  width: 100%;
  height: 100%;
  max-width: 400px;
  max-height: 400px;
}

@media (max-width: 600px) {
  .ant-table-tbody > tr > td {
    padding: 4px !important;
  }
}

@media (max-width: 400px) {
  .ant-table-tbody > tr > td {
    padding: 4px 2px !important;
  }
  .exercise-img {
    height: 24px;
  }
  .exercise-img-full {
    height: 100%;
  }
}
