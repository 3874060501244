.exercise-select {
  width: 80%;
  max-width: 150px;
}

.exercise-row {
  padding: 0px;
}

.exercise-option-div {
  width: 0px;
  padding: 0px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.exercise-img {
  filter: invert(0.9);
  padding: 2px 4px 2px 0;
  /* width: 40em; */
  height: 32px;
}
