@import "~antd/dist/antd.css";

.home-button {
  margin: 4px;
}

.home-img {
  width: 400px;
}

@media (max-width: 1500px) {
  .home-img {
    width: 250px;
  }
}

@media (max-width: 1100px) {
  .home-img {
    width: 200px;
  }
}

@media (max-width: 600px) {
  .home-img {
    width: 150px;
  }
}

@media (max-width: 400px) {
  .home-img {
    width: 100px;
  }
}
